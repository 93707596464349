<template>
<n-tabs
  type="segment"
  size="small"
  :value="currentTab"
  :disabled="loading"
  @update:value="changeTab">
  <n-tab-pane
    v-for="(tab, i) in tabs"
    :key="i"
    :name="tab.name"
    :tab="tab.title"
    :disabled="loading"
    class="rounded-lg overflow-hidden">
    <router-view></router-view>
  </n-tab-pane>
</n-tabs>
</template>

<script>
// vue
import { ref, computed, onMounted } from 'vue';

// store
import { useGl } from '@/store/gl';
import { useRefs } from '@/store/refs';
import { useMarketplace } from '@store/ts/marketplace';

// router
import { useRoute, useRouter } from 'vue-router';

// UI
import {
    NCard,
    NTabs,
    NTabPane } from 'naive-ui';

export default {
    name: 'marketplace-mentorship-desktop',
    components: {
        NCard,
        NTabs,
        NTabPane,
    },
    setup() {
        // router
        const route = useRoute();
        const router = useRouter();

        // store
        const gl = useGl();
        const refs = useRefs();
        const marketplace = useMarketplace();
            
        // vars
        const loading = computed(() => gl.loading);

        const currentTab = computed(() => {
            const current = route.name.split('.').reverse()[0];

            switch(current) {
                case 'mentors':
                    return 'mentors';
                case 'myMentors':
                    return 'myMentors';
                case 'iMentor':
                    return 'iMentor';
            };

            return 'mentors';
        });

        const tabs = computed(() => [
            {
                name: 'mentors',
                title: marketplace.localization['marketplace/tabs/mentors/title'],
            }, {
                name: 'myMentors',
                title: marketplace.localization['marketplace/tabs/my_mentors/title'],
            }, {
                name: 'iMentor',
                title: marketplace.localization['marketplace/tabs/i_mentor/title'],
            },
        ]);

        const changeTab = (tab) => {
            router.push({ name: `marketplace.mentorship.${tab}` });
        };
            
        onMounted( async () => {
            //
        });

        return {
            gl,
            refs,
            tabs,
            loading,
            currentTab,
            marketplace,
            changeTab,
        };
    },
};
</script>

<style lang="scss">
@import '@/css/stock-icon.scss';
</style>